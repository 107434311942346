import { Index } from 'elasticlunr'
import React, { ReactElement, useState } from 'react'
import { PaperProps } from '@material-ui/core'
import { IGalleryProps } from '@objects/overlay/types/gallery'
import { IDownloadShopOverlayProps } from '@objects/overlay/types/downloadShop'
import { IQuizKnowledgeOverlayProps } from '@objects/overlay/types/quizKnowledge'
import { IMaterialShopOverlayProps } from '@/objects/overlay/types/materialShop'

export interface IToggleComponentContext {
  OverlayIsActive: boolean
  toggleOverlay: (show: boolean, customProps?: Partial<ICustomProps>) => void
  customProps: ICustomProps
}

interface ICustomProps {
  trackingID: string
  type:
    | 'search'
    | 'default'
    | 'contactSuccess'
    | 'contactError'
    | 'gallery'
    | 'downloadShop'
    | 'materialShop'
    | 'orderError'
    | 'feedbackError'
    | 'addFileError'
    | 'quiz knowledge'
    | 'voting'
  dialogType: 'fullscreen' | 'small' | undefined
  width?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined
  closeButton: 'default' | 'hide' | 'invert'
  className?: string
  onAcceptance: () => void
  index?: Index<RvG.SearchResult>
  uniqueId?: string
  paperProps?: Partial<PaperProps>
  galleryProps?: IGalleryProps
  downloadProps: IDownloadShopOverlayProps
  quizKnowledgeProps: IQuizKnowledgeOverlayProps
  materialShopProps?: IMaterialShopOverlayProps
}

const ToggleComponentContext = React.createContext<IToggleComponentContext>({
  OverlayIsActive: false,
  toggleOverlay: () => {
    return null
  },
  customProps: {
    trackingID: '',
    type: 'default',
    dialogType: 'fullscreen',
    closeButton: 'default',
    onAcceptance: () => null,
    index: null as unknown as Index<RvG.SearchResult>,
    paperProps: {},
    galleryProps: {},
    downloadProps: {
      id: '',
      headline: '',
      amount: [],
      thumbnails: [],
      handleClose: () => ({}),
    },
    quizKnowledgeProps: {},
  },
})

export default function ToggleComponentProvider({
  children,
}: RvG.IReactDefaultProps): ReactElement {
  const [OverlayIsActive, setOverlayIsActive] = useState(false)
  const defaultCustomProps: ICustomProps = {
    trackingID: '',
    type: 'default',
    dialogType: 'fullscreen',
    closeButton: 'default',
    onAcceptance: () => null,
    index: null as unknown as Index<RvG.SearchResult>,
    paperProps: {},
    galleryProps: {},
    downloadProps: {
      id: '',
      headline: '',
      amount: [],
      thumbnails: [],
      handleClose: () => ({}),
    },
    quizKnowledgeProps: {},
  }
  const [customProps, setCustomProps] =
    useState<ICustomProps>(defaultCustomProps)

  function toggleOverlay(
    show: boolean,
    newCustomProps?: Partial<ICustomProps>
  ): void {
    setOverlayIsActive(show)
    if (customProps) {
      if (newCustomProps?.trackingID === 'suche') {
        handleSetSearchTitle()
      }
      newCustomProps &&
        (newCustomProps['paperProps'] = newCustomProps?.paperProps || {})
      setCustomProps({ ...customProps, ...newCustomProps })
    }
  }

  function handleSetSearchTitle() {
    document.title = 'Suche | Runter Vom Gas'
  }

  return (
    <ToggleComponentContext.Provider
      value={{ OverlayIsActive, toggleOverlay, customProps }}
    >
      {children}
    </ToggleComponentContext.Provider>
  )
}

export { ToggleComponentContext }
