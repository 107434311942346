import React, { ReactElement, useEffect } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
import {
  LogoJsonLd,
  SitelinksSearchBoxJsonLd,
  JsonLd,
  BreadcrumbJsonLd,
} from 'gatsby-plugin-next-seo'

import { makeStyles } from '@material-ui/core/styles'

import Header from '@components/header'
import Footer from '@components/footer'
import MetaTags from '@objects/metatags'
import Button from '@objects/button'
import Overlay from '@objects/overlay'
import BreadCrumb from '@objects/breadcrumb'
import Icon from '@objects/icon'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  $materialShopPaths,
  materialShopSetPaths,
  materialShopValidSession,
} from '@services/stores/materialShop'

const useStyles = makeStyles((theme) => ({
  layoutRoot: {},
  grey: {
    background: theme.palette.background.grey,
  },
  skipLink: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    fontWeight: 700,
    marginLeft: 8,
    marginTop: 2,
    marginBottom: 2,
    top: '-26px',
    left: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: `20px`,
    },
    '&:focus': {
      position: 'relative',
      top: '0px',
      textDecoration: 'underline',
    },
  },
}))

interface INavItemQuery {
  __typename: string
  navigation: string
  titleNavigation: string
  showInNavigation: string
  icon?: string
  url?: string
  fields: {
    fullPath: string
  }
  teaserCopy?: {
    raw: string
  }
  subPages?: INavItemQuery[]
}

type SchemaBreadcrumbsProps = {
  position?: number
  name?: string
  item?: string
}

export type LayoutProps = {
  children: React.ReactNode
  pageContext: RvG.PageHelpers.PageContext
}

export default function Layout({
  children,
  pageContext,
}: LayoutProps): ReactElement {
  const location = useLocation()
  const intl = useIntl()
  const schemaBreadcrumbList = [] as SchemaBreadcrumbsProps[]
  pageContext?.breadcrumbs?.forEach((item, i) => {
    schemaBreadcrumbList.push({
      position: i + 1,
      name: item?.label,
      item: (!!item.link && location.origin + item.link) || location.href,
    })
  })

  const classes = useStyles()

  const { siteSearchIndex, mainNav, metaNav, footerNav } =
    useStaticQuery(graphql`
      query {
        siteSearchIndex {
          index
        }
        mainNav: contentfulNavigation(
          identifier: { eq: "main-navigation" }
          pages: {
            elemMatch: {
              showInNavigation: { eq: "yes" }
              titleNavigation: { ne: null }
            }
          }
        ) {
          pages {
            showInNavigation
            titleNavigation
            fields {
              fullPath
            }
            teaserCopy {
              raw
            }
            subPages {
              ... on ContentfulGenericPage {
                __typename
                subPages {
                  ... on ContentfulGenericPage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                  ... on ContentfulArticlePage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                  ... on ContentfulNavigationLink {
                    __typename
                    titleNavigation
                    url
                  }
                  ... on ContentfulCampaignPage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                }
                showInNavigation
                titleNavigation
                fields {
                  fullPath
                }
              }
              ... on ContentfulDummyPage {
                __typename
                titleNavigation
                subPages {
                  ... on ContentfulGenericPage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                  ... on ContentfulArticlePage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                  ... on ContentfulNavigationLink {
                    __typename
                    titleNavigation
                    url
                  }
                  ... on ContentfulCampaignPage {
                    __typename
                    showInNavigation
                    titleNavigation
                    fields {
                      fullPath
                    }
                  }
                }
              }
              ... on ContentfulArticlePage {
                __typename
                showInNavigation
                titleNavigation
                fields {
                  fullPath
                }
              }
              ... on ContentfulCampaignPage {
                __typename
                showInNavigation
                titleNavigation
                fields {
                  fullPath
                }
              }
              ... on ContentfulNavigationLink {
                __typename
                titleNavigation
                url
              }
            }
          }
        }
        metaNav: contentfulNavigation(
          identifier: { eq: "meta-navigation" }
          pages: { elemMatch: { showInNavigation: { eq: "yes" } } }
        ) {
          pages {
            fields {
              fullPath
            }
            titleNavigation
            navigation
            icon
          }
        }
        footerNav: contentfulNavigation(
          identifier: { eq: "footer-navigation" }
          pages: { elemMatch: { showInNavigation: { eq: "yes" } } }
        ) {
          pages {
            fields {
              fullPath
            }
            titleNavigation
            navigation
          }
        }

        globalText: allContentfulGlobalText {
          nodes {
            key
            value
          }
        }
      }
    `)

  useEffect(() => {
    let paths = $materialShopPaths.get()

    if (!paths.isHydrated) {
      paths = {
        cart: intl.formatMessage({ id: 'materialshop.cart.url' }),
        checkout: intl.formatMessage({ id: 'materialshop.checkout.url' }),
        shop: intl.formatMessage({ id: 'materialshop.shop.url' }),
        thanks: intl.formatMessage({ id: 'materialshop.thanks.url' }),
        login: intl.formatMessage({ id: 'materialshop.login.url' }),
      }
      materialShopSetPaths(paths)
    }

    if (!location.pathname) return

    const locationPath = `${location.pathname.replace(/\/$/, '')}/`
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isHydrated, ...rest } = paths

    if (!Object.values(rest).some((path) => locationPath.startsWith(path))) {
      return
    }

    if (!materialShopValidSession(location.pathname)) {
      navigate(paths.login)
    }
  }, [location.pathname])

  const filteredMainItems = mainNav?.pages
    .filter((page: INavItemQuery) => {
      return page.showInNavigation === 'yes'
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        path: page.fields.fullPath,
        teaserCopy: page.teaserCopy
          ? {
              raw: page.teaserCopy.raw,
              references: [],
            }
          : null,
        nextlevel: page.subPages
          ?.filter((page) => {
            return (
              (page.showInNavigation && page.showInNavigation === 'yes') ||
              page.__typename === 'ContentfulNavigationLink' ||
              page.__typename === 'ContentfulDummyPage'
            )
          })
          .map((page) => {
            return {
              title: page.titleNavigation,
              path:
                page.__typename === 'ContentfulNavigationLink'
                  ? page.url
                  : page.fields?.fullPath,
              nextlevel: page.subPages
                ?.filter((page) => {
                  return (
                    (page.showInNavigation &&
                      page.showInNavigation === 'yes') ||
                    page.__typename === 'ContentfulNavigationLink' ||
                    page.__typename === 'ContentfulDummyPage'
                  )
                })
                .map((page) => {
                  return {
                    title: page.titleNavigation,
                    path:
                      page.__typename === 'ContentfulNavigationLink'
                        ? page.url
                        : page.fields?.fullPath,
                  }
                }),
            }
          }),
      }
    })

  const metaItems = metaNav?.pages.map((page: INavItemQuery) => {
    return {
      title: page.titleNavigation,
      path: page.fields.fullPath,
      icon: page.icon,
    }
  })

  const footerItems = footerNav?.pages.map((page: INavItemQuery) => {
    return {
      title: page.titleNavigation,
      path: page.fields.fullPath,
      icon: page.icon,
    }
  })

  const excludedUrls = ['/']

  function onClickSkipLink(e: {
    preventDefault: () => void
    currentTarget: { getAttribute: (arg0: string) => any }
  }) {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('href')
    const target = document.querySelector(id)
    target.focus()
  }

  return (
    <div
      className={clsx(classes.layoutRoot, {
        [pageContext?.frontmatter?.pageBackground
          ? classes[pageContext?.frontmatter?.pageBackground]
          : '']: pageContext?.frontmatter?.pageBackground,
      })}
      aria-label={intl.formatMessage({
        id: 'layout.aria.label',
      })}
    >
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />

      <LogoJsonLd
        logo="https://www.runtervomgas.de/img/logo/rvg_logo.svg"
        url={pageContext.canonical}
      />
      <SitelinksSearchBoxJsonLd
        url={pageContext.canonical}
        searchHandlerQueryStringUrl={`${location.origin}/suchergebnisse/#searchquery`}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'GovernmentOrganization',
          legalName: 'Bundesministerium für Digitales und Verkehr',
          name: 'Runter Vom Gas',
          address: {
            streetAddress: 'Invalidenstraße 44',
            addressLocality: 'Berlin',
            addressRegion: 'BER',
            postalCode: '10115',
            addressCountry: 'DE',
          },
          contactPoint: [
            {
              telephone: '+4930183000',
              contactType: 'customer service',
              areaServed: 'DE',
              availableLanguage: ['German', 'English'],
            },
          ],
        }}
      />
      <a className={classes.skipLink} href="#main" onClick={onClickSkipLink}>
        <FormattedMessage id="navigation.skiplink" />
        <Icon name="ArrowDown" size="small" />
      </a>

      <Header
        searchIndex={siteSearchIndex.index}
        mainnav={filteredMainItems}
        metanav={metaItems}
      />
      <Overlay />
      <main
        aria-label={intl.formatMessage({
          id: 'layout.main.aria.label',
        })}
        role="main"
      >
        <div id="main" tabIndex={0} />
        {pageContext.breadcrumbs && !excludedUrls.includes(pageContext.url) && (
          <>
            <BreadcrumbJsonLd itemListElements={schemaBreadcrumbList} />
            <BreadCrumb crumbList={pageContext.breadcrumbs} />
          </>
        )}

        {children}
      </main>
      <Button type="icon" backtop />
      <Footer navItems={footerItems} />
    </div>
  )
}
