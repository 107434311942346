import React, { useEffect, useState } from 'react'
import { IDownloadShopSelectOpt } from '@objects/overlay/types/downloadShop'
import { useLocation } from '@reach/router'

export interface IBasketContext {
  addItem: (item: {
    amount: number
    thumb: RvG.Contentful.IAsset
    name: string
    amountOpts: Array<IDownloadShopSelectOpt>
    id: string
  }) => void
  removeItem: (itemId: string) => void
  clearBasket: () => void
  setDeleted: (itemId: string, deleted: boolean) => void
  basket: IBasketItem[]
  pathToCart: string
  pathToCheckout: string
  pathToShop: string
}

export interface IBasketItem {
  id: string
  thumb: RvG.Contentful.IAsset
  name: string
  amount: number
  deleted?: boolean
  amountOpts: Array<IDownloadShopSelectOpt>
}

const BasketComponentContext = React.createContext<IBasketContext>({
  addItem: () => {
    return null
  },
  removeItem: () => {
    return null
  },
  clearBasket: () => {
    return null
  },
  setDeleted: () => {
    return null
  },
  basket: [],
  pathToCart: '',
  pathToCheckout: '',
  pathToShop: '',
})

export default function BasketComponentProvider({
  children,
}: RvG.IReactDefaultProps) {
  const storageKey = 'basketStorage'
  const [basket, setBasket] = useState<IBasketItem[]>(() => {
    return []
  })

  const location = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const basketStorage = window.localStorage.getItem(storageKey)

      const basketStorageParsed: IBasketItem[] = basketStorage
        ? JSON.parse(basketStorage)
        : []
      setBasket(() => basketStorageParsed)
    }
  }, [])

  useEffect(() => {
    cleanupBasket()
  }, [location])

  function addItem(item: IBasketItem) {
    item.deleted = false

    setBasket((basket) => {
      const newBasket: IBasketItem[] | [] = basket.filter((basketItem) => {
        return basketItem.id !== item.id
      })
      newBasket.push(item)

      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))

      return newBasket
    })
  }

  function removeItem(itemId: string) {
    setBasket((basket) => {
      const newBasket: IBasketItem[] | [] = basket.filter((basketItem) => {
        return basketItem.id !== itemId
      })
      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))
      return newBasket
    })
  }

  function clearBasket() {
    setBasket(() => {
      window.localStorage.setItem(storageKey, JSON.stringify([]))
      return []
    })
  }

  function setDeleted(itemId: string, deleted: boolean) {
    setBasket((basket) => {
      const newBasket: IBasketItem[] | [] = basket.map((basketItem, index) => {
        if (basketItem.id === itemId) {
          basketItem.deleted = deleted
        }
        return basketItem
      })
      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))
      return newBasket
    })
  }

  function cleanupBasket() {
    setBasket((basket) => {
      const newBasket: IBasketItem[] | [] = basket.filter((basketItem) => {
        return !basketItem.deleted
      })
      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))
      return newBasket
    })
  }

  return (
    <BasketComponentContext.Provider
      value={{
        addItem,
        removeItem,
        clearBasket,
        setDeleted,
        basket,
        pathToCart: '/aktuelles-und-downloads/warenkorb/',
        pathToCheckout: '/aktuelles-und-downloads/checkout/',
        pathToShop: '/aktuelles-und-downloads/',
      }}
    >
      {children}
    </BasketComponentContext.Provider>
  )
}

export { BasketComponentContext }
