import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Icon from '@objects/icon'
import Carousel from '@objects/carousel'
import Button from '@objects/button'
import Copy from '@components/copy'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from '@objects/image'
import { Select } from '@objects/formfields'
import useBasket from '@hooks/useBasket'
import { FormattedMessage } from 'react-intl'

export interface IDownloadShopOverlayProps {
  id: string
  headline: string
  copy?: RvG.Contentful.BasicRichTextType
  thumbnails: Array<RvG.Contentful.IAsset>
  file?: RvG.Contentful.IAsset
  amount: Array<RvG.Contentful.IContentfulModuleDownloadShopItemAmount> | []
  handleClose?: () => void
}

export interface IDownloadShopSelectOpt {
  value: string | number
  label: string | number
}

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 12px)',
    maxWidth: theme.container.lg,
    // maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: theme.spacing(12, 4),
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100vh',
      overflow: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
      width: '100%',
      flexDirection: 'row',
    },
  },
  infoWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
      paddingLeft: theme.spacing(8),
    },

    display: 'flex',
    flexDirection: 'column',
  },
  infoInner: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  infoHeadline: {
    ...theme.typography.h5,
  },
  close: {
    cursor: 'pointer',
    marginBottom: 'auto',
    textAlign: 'right',
    fontSize: '32px',
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  galleryWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  galleryInner: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    zIndex: theme.zIndex.modal + 1,
    padding: theme.spacing(0, 4),
    background: theme.palette.background.greyDark,
    '& .swiper-wrapper': {
      alignItems: 'center',
    },
  },
  galleryItem: {
    height: '100%',
    maxHeight: '80vh',
    width: '90%',
    margin: 'auto',
  },
  navBtn: {
    position: 'absolute !important' as 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    width: '28px',
    height: '71px',
  },
  navBtnPrev: {
    left: theme.spacing(0),
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  navBtnNext: {
    right: theme.spacing(0),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  downloadWrapper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '50%',
    },
  },
  basketAmountSelectWrapper: {
    marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(25),
  },
  download: {},
  downloadInfoText: {
    marginTop: theme.spacing(8),
    marginbottom: theme.spacing(4),
  },
  hide: {
    display: 'none',
  },
}))

const getSelectOptions = (
  amount: Array<RvG.Contentful.IContentfulModuleDownloadShopItemAmount>
) => {
  const arr10 = Array.from({ length: 10 }, (_, i) => i + 1)
  const arr10opts = arr10?.map((n) => {
    return { value: n, label: n }
  })

  let selectOptions: Array<IDownloadShopSelectOpt> = []

  amount?.forEach((amountItem) => {
    if (10 === amountItem.content) {
      selectOptions = selectOptions.concat(arr10opts)
    } else {
      selectOptions.push({
        value: amountItem.content,
        label: amountItem.content,
      })
    }
  })
  return selectOptions
}

export default function DownloadShopOverlay({
  ...props
}: IDownloadShopOverlayProps) {
  const classes = useStyles()
  const [swiper, setSwiper] = useState<any>({})
  const muiTheme = useTheme()
  const isMedium = useMediaQuery(muiTheme.breakpoints.up('md'))
  const { addItem } = useBasket()
  const [basketAmount, setBasketAmount] = useState<number>(1)

  const { id, headline, copy, thumbnails, handleClose, file, amount } = props

  const renderItems = ({
    thumbnails,
  }: {
    thumbnails: RvG.Contentful.IAsset[]
  }) => {
    return thumbnails?.map((thumbnail: RvG.Contentful.IAsset) => {
      return (
        <div key={`DLThumb-${thumbnail.id}`}>
          <Image className={classes.galleryItem} image={thumbnail.fluid} />
        </div>
      )
    })
  }

  return (
    <div className={classes.popupWrapper}>
      <div
        className={clsx(classes.close, { [classes.hide]: isMedium })}
        onClick={handleClose}
      >
        <Icon size={'inherit'} name={'Close'} />
      </div>
      <div className={classes.galleryWrapper}>
        <div className={classes.galleryInner}>
          <Carousel onSwiper={setSwiper} speed={600} loop={true}>
            {renderItems({ thumbnails })}
          </Carousel>

          {thumbnails?.length > 1 && (
            <>
              <Button
                type="icon"
                icon="ChevronLeft"
                className={clsx(classes.navBtn, classes.navBtnPrev)}
                onClick={() => swiper.slidePrev()}
              />
              <Button
                type="icon"
                icon="ChevronRight"
                className={clsx(classes.navBtn, classes.navBtnNext)}
                onClick={() => swiper.slideNext()}
              />
            </>
          )}
        </div>
        {thumbnails?.length > 1 && (
          <div>{/*<span>x von {thumbnails.length}</span>*/}</div>
        )}
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.infoInner}>
          <div
            className={clsx(classes.close, {
              [classes.hide]: !isMedium,
            })}
            onClick={handleClose}
          >
            <Icon size={'inherit'} name={'Close'} />
          </div>
          <h3 className={classes.infoHeadline}>{headline}</h3>
          {copy && <Copy richtext={copy} />}
          <div className={classes.downloadWrapper}>
            <div className={classes.download}>
              {amount?.length > 0 && (
                <React.Fragment>
                  {amount.length === 1 && amount[0].content !== 1 && (
                    <div className={classes.basketAmountSelectWrapper}>
                      <Select
                        settings={{
                          settingsTheme: 'dark',
                          options: (() =>
                            getSelectOptions(amount) as ReadonlyArray<any>)(),
                          onChange: (item) => {
                            item?.value &&
                              setBasketAmount(parseInt(item?.value))
                          },
                          defaultValue: { label: 1, value: 1 } as any,
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <Button
                      icon={'Basket'}
                      onClick={() => {
                        addItem({
                          id: id,
                          amount: basketAmount,
                          thumb: thumbnails[0],
                          name: headline,
                          amountOpts: getSelectOptions(amount),
                        })
                      }}
                    >
                      <FormattedMessage id={'media.shop.basket.add'} />
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {file && file?.file && (
                <>
                  {file?.file?.details?.size && (
                    <p className={classes.downloadInfoText}>
                      {' '}
                      Als PDF (
                      {file?.file?.details?.size < '1048576'
                        ? Math.floor(parseInt(file?.file?.details?.size) / 1024)
                        : (
                            Math.floor(
                              parseInt(file?.file?.details?.size) / 1024
                            ) / 1024
                          )
                            .toFixed(2)
                            .replace(/\./g, ',')}
                      {file?.file?.details?.size < '1048576' ? ' KB' : ' MB'})
                      download
                    </p>
                  )}
                  <a
                    href={file.file.url}
                    target={'_blank'}
                    download
                    rel="noreferrer"
                  >
                    <Button icon={'Download'}>Download</Button>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
