import { WritableAtom, action, atom } from 'nanostores'

export interface IMaterialShopCartItemValues {
  fields?: { [key: string]: string }
  sections?: { [key: string]: string }[]
}

export interface IMaterialShopCartItem
  extends RvG.Contentful.IContentfulModuleMaterialShopItem {
  id: string
  title: string
  name: string
  category: RvG.Contentful.IContentfulMaterialShopCategory
  orderInCart: boolean
  values: IMaterialShopCartItemValues
}

export interface IMaterialShopOrderInCartItem
  extends RvG.Contentful.IContentfulMaterialShopItem {
  id: string
  title: string
  name: string
  category: RvG.Contentful.IContentfulMaterialShopCategory
}

export interface IMaterialShopCart {
  path: string
  pathToCheckout: string
  pathToShop: string
  orderInCartItems: IMaterialShopOrderInCartItem[]
  items: IMaterialShopCartItem[]
}

export interface IMaterialShopPaths {
  cart: string
  checkout: string
  shop: string
  login: string
  thanks: string
  isHydrated?: boolean
}

export const $materialShopPaths = atom<IMaterialShopPaths>({
  cart: '/bestellmaterialien/cart/',
  checkout: '/bestellmaterialien/checkout/',
  shop: '/bestellmaterialien/',
  login: '/bestellmaterialien/login/',
  thanks: '/bestellmaterialien/thanks/',
})

export const $materialShopMeta = atom<
  | {
      contact: RvG.Contentful.BasicRichTextType
    }
  | undefined
>()

export const $materialShopSessionId = atom<string | undefined>()

export const $materialShopCart = atom<IMaterialShopCart>({
  path: $materialShopPaths.get().cart,
  pathToCheckout: $materialShopPaths.get().checkout,
  pathToShop: $materialShopPaths.get().shop,
  orderInCartItems: [],
  items: [],
})

export const materialShopAddItem = action<
  typeof $materialShopCart,
  (
    store: WritableAtom<IMaterialShopCart>,
    item: RvG.Contentful.IContentfulModuleMaterialShopItem,
    values: IMaterialShopCartItemValues
  ) => void
>($materialShopCart, 'materialShopAddItem', async (store, item, values) => {
  store.set({
    ...store.get(),
    items: [
      ...store.get().items.filter((entry) => entry.id !== item.id),
      {
        ...item,
        values,
      },
    ],
  })
})

export const materialShopRemoveItem = action<
  typeof $materialShopCart,
  (
    store: WritableAtom<IMaterialShopCart>,
    item: RvG.Contentful.IContentfulModuleMaterialShopItem
  ) => void
>($materialShopCart, 'materialShopRemoveItem', async (store, item) => {
  const items = store.get().items.filter((entry) => entry.id !== item.id)
  store.set({
    ...store.get(),
    items,
  })
})

export const materialShopClearCart = action<
  typeof $materialShopCart,
  (store: WritableAtom<IMaterialShopCart>) => void
>($materialShopCart, 'materialShopClearCart', async (store) => {
  const items: IMaterialShopCartItem[] = []
  store.set({
    ...store.get(),
    items,
  })
})

export const materialShopSetOrderInCartItems = action<
  typeof $materialShopCart,
  (
    store: WritableAtom<IMaterialShopCart>,
    items: RvG.Contentful.IContentfulModuleMaterialShopItem[]
  ) => void
>(
  $materialShopCart,
  'materialShopSetOrderInCartItems',
  async (store, items) => {
    store.set({
      ...store.get(),
      orderInCartItems: items,
    })
  }
)

export const materialShopValidSession = action<
  typeof $materialShopSessionId,
  (store: WritableAtom<string | undefined>) => boolean
>($materialShopSessionId, 'materialShopValidSession', (store): boolean => {
  if (!store.get()) {
    return false
  }
  return true
})

export const materialShopSetPaths = action<
  typeof $materialShopPaths,
  (store: WritableAtom<IMaterialShopPaths>, paths: IMaterialShopPaths) => void
>($materialShopPaths, 'materialShopSetPaths', async (store, paths) => {
  $materialShopCart.set({
    ...$materialShopCart.get(),
    path: paths.cart,
    pathToCheckout: paths.checkout,
    pathToShop: paths.shop,
  })
  store.set({ ...paths, isHydrated: true })
})

export const materialShopConfig: {
  categories: {
    [key in RvG.Contentful.IContentfulMaterialShopCategory['identifier']]: {
      hintId?: string
      form?: {
        labelId?: string
        fields?: {
          id: string
          labelId: string
          type: 'text' | 'email' | 'tel' | 'number'
          defaultValue?: string
          isRequired: boolean
        }[]
        sections?: {
          labelId: string
          fields: {
            id: string
            labelId: string
            type: 'text' | 'email' | 'tel' | 'number'
            isRequired: boolean
            defaultValue?: string
          }[]
        }[]
      }
    }
  }
} = {
  categories: {
    bigEvents: {
      hintId: 'materialshop.category.bigEvents.hint',
      form: {
        sections: [
          {
            labelId: 'materialshop.form.contactDataBigEvents',
            fields: [
              {
                id: 'contactPerson',
                labelId: 'materialshop.form.contactPerson',
                type: 'text',
                isRequired: true,
              },
              {
                id: 'phone',
                labelId: 'materialshop.form.phone',
                type: 'tel',
                isRequired: true,
              },
              {
                id: 'email',
                labelId: 'materialshop.form.email',
                type: 'email',
                isRequired: true,
              },
            ],
          },
          {
            labelId: 'materialshop.form.eventData',
            fields: [
              {
                id: 'date',
                labelId: 'materialshop.form.date',
                type: 'text',
                isRequired: true,
              },
              {
                id: 'reason',
                labelId: 'materialshop.form.reason',
                type: 'text',
                isRequired: true,
              },
              {
                id: 'numberOfExpectedVisitors',
                labelId: 'materialshop.form.numberOfExpectedVisitors',
                type: 'text',
                isRequired: true,
              },
            ],
          },
        ],
      },
    },
    smallEvents: {
      form: {
        labelId: 'materialshop.form.contactData',
        fields: [
          {
            id: 'contactPerson',
            labelId: 'materialshop.form.contactPerson',
            type: 'text',
            isRequired: true,
          },
          {
            id: 'phone',
            labelId: 'materialshop.form.phone',
            type: 'tel',
            isRequired: true,
          },
          {
            id: 'email',
            labelId: 'materialshop.form.email',
            type: 'email',
            isRequired: true,
          },
        ],
        sections: [
          {
            labelId: 'materialshop.form.eventData',
            fields: [
              {
                id: 'date',
                labelId: 'materialshop.form.date',
                type: 'text',
                isRequired: true,
              },
              {
                id: 'reason',
                labelId: 'materialshop.form.reason',
                type: 'text',
                isRequired: true,
              },
              {
                id: 'deliveryAddress',
                labelId: 'materialshop.form.deliveryAddress.optional',
                type: 'text',
                isRequired: false,
              },
            ],
          },
        ],
      },
    },

    poster: {
      form: {
        labelId: 'materialshop.form.chooseSize',
        fields: [
          {
            id: 'DINA0',
            labelId: 'materialshop.form.size.dina0',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
          {
            id: 'DINA1',
            labelId: 'materialshop.form.size.dina1',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
          {
            id: 'DINA2',
            labelId: 'materialshop.form.size.dina2',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
        ],
      },
    },
    bridgeBanners: {
      form: {
        labelId: 'materialshop.form.chooseMaterial',
        fields: [
          {
            id: 'windPermeable',
            labelId: 'materialshop.form.windPermeable',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
          {
            id: 'notWindPermeable',
            labelId: 'materialshop.form.notWindPermeable',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
        ],
      },
    },
    promotionBanners: {
      form: {
        labelId: 'materialshop.form.chooseMaterial',
        fields: [
          {
            id: 'windPermeable',
            labelId: 'materialshop.form.windPermeable',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
          {
            id: 'notWindPermeable',
            labelId: 'materialshop.form.notWindPermeable',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
        ],
      },
    },
    promoStuff: {
      form: {
        labelId: 'materialshop.form.chooseAmount',
        fields: [
          {
            id: 'numberOfPieces',
            labelId: 'materialshop.form.numberOfPieces',
            type: 'number',
            defaultValue: '0',
            isRequired: true,
          },
        ],
      },
    },
    pressSocial: {},
  },
}
