import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  content: {
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(15),
    },
    textAlign: 'center',
  },
  headline: {
    marginBottom: theme.spacing(5),
  },
  copy: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  linebreak: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  link: {
    ...theme.typography.link,
  },
}))

export type PopupProps = {
  contentType: 'newsletterError' | 'newsletterSuccess'
  onAcceptance: () => void
}

interface IContent {
  img?: string
  headline?: string
  copy: string
  button: {
    label: string
    action: () => void
  }
}

export default function Popup({
  contentType,
  onAcceptance,
}: PopupProps): React.ReactElement {
  const intl = useIntl()
  const classes = useStyles()

  const content: Record<string, IContent> = {
    netiquette: {
      img: '/img/popups/netiquette.jpg',
      headline: 'dialogue.popup.netiquette.headline',
      copy: 'dialogue.popup.netiquette.copy',
      button: {
        label: 'dialogue.popup.netiquette.button',
        action: onAcceptance,
      },
    },
    confirmation: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.confirmation.headline',
      copy: 'dialogue.popup.confirmation.copy',
      button: {
        label: 'dialogue.popup.confirmation.button',
        action: onAcceptance,
      },
    },
    editorapproval: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.editorapproval.headline',
      copy: 'dialogue.popup.editorapproval.copy',
      button: {
        label: 'dialogue.popup.editorapproval.button',
        action: onAcceptance,
      },
    },
    blacklist: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.blacklist.headline',
      copy: 'dialogue.popup.blacklist.copy',
      button: {
        label: 'dialogue.popup.blacklist.button',
        action: onAcceptance,
      },
    },
    newsletterSuccess: {
      copy: 'newsletter.subscribe.thank',
      button: {
        label: 'newsletter.subscribe.button',
        action: onAcceptance,
      },
    },
    newsletterError: {
      copy: 'newsletter.subscribe.error',
      button: {
        label: 'newsletter.subscribe.button',
        action: onAcceptance,
      },
    },
    contactSuccess: {
      copy: 'contact.thank',
      button: {
        label: 'contact.thank.button',
        action: onAcceptance,
      },
    },
    contactError: {
      copy: 'contact.error',
      button: {
        label: 'contact.error.button',
        action: onAcceptance,
      },
    },
    orderError: {
      copy: 'basket.checkout.order.error',
      button: {
        label: 'basket.checkout.order.error.button',
        action: onAcceptance,
      },
    },
    feedbackError: {
      copy: 'events.send.error',
      button: {
        label: 'events.send.error.button',
        action: onAcceptance,
      },
    },
    addFileError: {
      copy: 'events.send.image.error',
      button: {
        label: 'events.send.image.error.button',
        action: onAcceptance,
      },
    },
  }

  return (
    <>
      {content[contentType]?.img && (
        <Img
          className={classes.img}
          fluid={{
            src: content[contentType].img || '',
            aspectRatio: 2.6417,
            srcSet: '',
            sizes: '',
            media: `(min-width: 0px)`,
          }}
          alt=""
          backgroundColor={true}
        />
      )}
      {content[contentType] && (
        <div className={classes.content}>
          {content[contentType].headline && (
            <Headline id="dialog-title" level={3} className={classes.headline}>
              <FormattedMessage id={content[contentType].headline} />
            </Headline>
          )}
          <Paragraph className={classes.copy}>
            <FormattedMessage
              id={content[contentType].copy}
              values={{
                // eslint-disable-next-line react/display-name
                a: (...chunks: Array<unknown>) => (
                  <a
                    className={classes.link}
                    rel="noreferrer"
                    target="_blank"
                    href={intl.formatMessage({ id: 'netiquette.path' })}
                  >
                    {chunks}
                  </a>
                ),
                break: <span className={classes.linebreak} />,
              }}
            />
          </Paragraph>
          <Button onClick={content[contentType].button.action}>
            <FormattedMessage id={content[contentType].button.label} />
          </Button>
        </div>
      )}
    </>
  )
}
